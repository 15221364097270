import { FeatureCollection } from 'geojson';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import MapGL, {
  Layer,
  MapLayerMouseEvent,
  MapProvider,
  Popup,
  Source,
} from 'react-map-gl';
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from './map-styles';
import { StoreProps } from './index';

const mapboxKey = get(process.env, 'REACT_APP_MAPBOX_TOKEN', '');

export function Map({
  stores,
}: {
  stores: Pick<StoreProps, 'name' | 'address' | 'status'>[] | null | undefined;
}) {
  const [selectedStore, setSelectedStore] = useState<any | null>(null);

  const geojson: FeatureCollection = useMemo(() => {
    //get stores with location
    if (!stores) return { type: 'FeatureCollection', features: [] };
    return {
      type: 'FeatureCollection',
      features: stores
        // Filter out stores whose address was not found
        .filter((store) => store.address?.lat !== undefined && store.address?.lng !== undefined)
        .map((store) => ({
          type: 'Feature',
          properties: {
            name: store.name,
            address: store.address,
          },
          geometry: {
            type: 'Point',
            coordinates: [store.address!.lng!, store.address!.lat!],
          },
        })),
    };
  }, [stores]);

  const handleClick = (event: MapLayerMouseEvent) => {
    if (!event.features || !stores) return; // Check if features is defined

    const feature = event.features.find((f) => f.layer.id === 'store-points');
    if (feature) {
      const store = stores.find(
        (store) => store.name === feature.properties?.name,
      );
      setSelectedStore(store);
    }
  };

  return (
    <MapProvider>
      <MapGL
        attributionControl={true}
        initialViewState={{
          latitude: 37.0902, // Latitude central da América
          longitude: -95.7129, // Longitude central da América
          zoom: 2, // Zoom inicial para visualizar a maior parte da América
        }}
        mapStyle="mapbox://styles/mapbox/dark-v9"
        mapboxAccessToken={mapboxKey}
        onClick={handleClick}
        interactiveLayerIds={[clusterLayer.id || '']}
        style={{
          width: '100%',
          height: 'calc(100vh - 260px)',
          maxHeight: '100%',
        }}
      >
        {selectedStore && (
          <Popup
            latitude={selectedStore.location[1]}
            longitude={selectedStore.location[0]}
            onClose={() => setSelectedStore(null)}
          >
            <div>
              <h2>{selectedStore.name}</h2>
              <p>{selectedStore.address}</p>
            </div>
          </Popup>
        )}

        <Source
          id="earthquakes"
          type="geojson"
          data={geojson}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      </MapGL>
    </MapProvider>
  );
}
