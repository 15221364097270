import { FC, useEffect, useMemo } from 'react';
import { Modal } from './styles';
import Completion from './Completion';
import ApprovalButtons from '../ApprovalButtons';
import { useSubmissionDialog } from './dialog-hook';
import { Button, Space } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Switch, Case, Default } from 'react-if';
import Compare from './compare';
import { SalesIncentiveCompletion } from '@/types';
import { useAudit } from '@/hooks';

type Args = {
  onClose: () => void;
  onRefresh?: () => void;
  disableApproval?: boolean;
};

const SubmissionDialog: FC<Args> = ({
  onClose,
  onRefresh,
  disableApproval = false,
}) => {
  const submissionDialog = useSubmissionDialog();
  const audit = useAudit({
    resourceName: 'sales-incentive-approval-modal',
  });

  const showApprovalButtons = useMemo(() => {
    return (
      !disableApproval &&
      submissionDialog.state.completion?.status === 'complete' &&
      !submissionDialog.state.completion?.processing &&
      submissionDialog.state.mode === 'single'
    );
  }, [
    submissionDialog.state.completion?.status,
    submissionDialog.state.completion?.processing,
    submissionDialog.state.mode,
    disableApproval,
  ]);

  useEffect(() => {
    if (submissionDialog.state.open) {
      audit.onDetail({
        target: submissionDialog.state.mode,
        data: {
          completionId: submissionDialog.state.completion?.id,
        },
      });
    }
  }, [submissionDialog.state.open, submissionDialog.state.mode]);

  return (
    <Modal
      title={
        submissionDialog.state.mode === 'compare' ? (
          <Space>
            <span>Compare Completions</span>{' '}
            <Button
              icon={<CloseSquareOutlined />}
              type="dashed"
              onClick={() => submissionDialog.switchMode('single')}
            >
              Close compare
            </Button>
          </Space>
        ) : (
          <>Completion Details</>
        )
      }
      centered
      open={submissionDialog.state.open}
      width={submissionDialog.state.mode === 'compare' ? 1300 : 900}
      onCancel={onClose}
      destroyOnClose
      footer={
        showApprovalButtons && submissionDialog.state.completion?.id ? (
          <ApprovalButtons
            completionsId={[submissionDialog.state.completion?.id]}
            onCompleted={() => {
              onRefresh?.();
              submissionDialog.setProcessing();
            }}
          />
        ) : (
          <></>
        )
      }
    >
      <Switch>
        <Case condition={submissionDialog.state.mode === 'compare'}>
          <Compare />
        </Case>
        <Default>
          <Completion
            completion={
              submissionDialog.state.completion as SalesIncentiveCompletion
            }
          />
        </Default>
      </Switch>
    </Modal>
  );
};

export default SubmissionDialog;
