import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import {
  Button,
  Space,
  Table,
  Tag,
  notification,
  Input,
  Avatar,
  Typography,
  Dropdown,
  Row,
  Col,
  Divider,
  Select,
  Modal,
  Form,
  MenuProps,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  changeUserPoeStatus,
  deleteAdminUser,
} from '@/services/admin-user.service';
import {
  addWhitelistPhoneNumberToUser,
  adminConfirmUser,
  removeWhitelistPhoneNumberToUser,
  updateRiskLevel,
} from '@/services/mobile-user.service';
import { get, startCase } from 'lodash';
import { useSession } from '@/store';
import { LuActivity } from 'react-icons/lu';
import { BiUser } from 'react-icons/bi';
import { PiDotsThreeOutline, PiPencil } from 'react-icons/pi';
import { Container, RiskLevelHighAlert, RiskLevelSwitch } from './styles';
import { GrView } from 'react-icons/gr';
import { GoVerified } from 'react-icons/go';
import { TbExchange } from 'react-icons/tb';
import UserStatistics from './UserStatistics';
import { MobileUser } from '@/types';
import DrawerQuickView from './DrawerQuickView';
import { Case, Switch, When } from 'react-if';
import { BsAsterisk } from 'react-icons/bs';
import { SiStackedit } from 'react-icons/si';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import DrawerPoeView from '@/pages/MobileUser/List/DrawerPoeView';
import { formatISODate } from '@/utils';
import { ChangeStoreModal } from '../ChangeStoreModal';

type Args = {
  mode?: 'page' | 'subpage';
  storeId?: string;
};

const MobileUserList: FC<Args> = ({ mode = 'page', storeId = '' }) => {
  if (mode === 'subpage') {
    return <TableContainer mode={mode} storeId={storeId} />;
  }

  return (
    <Layout noCard={false}>
      <PageHeader noBackButton title="Mobile Users" />

      <Container>
        <TableContainer mode={mode} storeId={storeId} />
      </Container>
    </Layout>
  );
};

const TableContainer = ({
  mode,
  storeId,
}: {
  mode: 'page' | 'subpage';
  storeId: string;
}) => {
  const navigate = useNavigate();
  const session = useSession();
  const [filters, setFilters] = useState({
    text: '',
    storeId,
    isDeleted: 'N',
  });
  const [userPreviewOpen, setUserPreviewOpen] = useState(false);
  const [userPoePreviewOpen, setUserPoePreviewOpen] = useState(false);
  const { tableProps, refresh } = useTable<MobileUser>({
    service: 'user/read',
    path: `admin/mobile-user/list`,
    params: {
      filters,
    },
    perPage: 10,
    defaultSort: {
      field: 'updatedAt',
      order: 'descend',
    },
  });
  const [previewUserId, setPreviewUserId] = useState<string | null>(null);
  const [riskLevelForm, setRiskLevelForm] = useState({
    modalOpen: false,
    userId: '',
    originalRiskLevel: '',
    riskLevel: '',
    isLoading: false,
  });
  const [changeStoreModalData, setChangeStoreModalData] = useState<{
    userId: string;
    storeId: string | undefined;
  } | null>(null);

  const audit = useAudit({
    resourceName: 'mobile-user',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const [form] = Form.useForm();
  const [whitelistModalForm, setWhitelistModalForm] = useState({
    modalOpen: false,
    loading: false,
    userId: '',
    notes: '',
    modalTitle: '',
    executeOperation: '',
  });

  const [confirmUserForm, setConfirmUserForm] = useState({
    modalOpen: false,
    loading: false,
    userId: '',
  });

  const onUpdateRiskLevel = async () => {
    setRiskLevelForm((prev) => ({ ...prev, isLoading: true }));
    const { status, message } = await updateRiskLevel({
      userId: riskLevelForm.userId,
      riskLevel: riskLevelForm.riskLevel,
    });
    audit.onClick({ target: 'update-risk-level' });

    if (status) {
      notification.success({
        message: 'Risk level updated successfully',
        placement: 'bottomRight',
        duration: 2,
      });

      refresh();

      setRiskLevelForm((prev) => ({
        ...prev,
        modalOpen: false,
        isLoading: false,
      }));

      return;
    }

    notification.error({
      message: 'Failed to update risk level',
      description: message,
      placement: 'bottomRight',
      duration: 2,
    });

    setRiskLevelForm((prev) => ({
      ...prev,
      isLoading: false,
      modalOpen: false,
    }));
  };

  const onDeleteUser = async (id: string | undefined) => {
    if (!id) return;

    const { status } = await deleteAdminUser(id);

    if (status) {
      notification.success({
        message: 'User deleted successfully',
        placement: 'bottomRight',
        duration: 2,
      });

      refresh();

      return;
    }

    notification.error({
      message: 'Failed to delete user',
      placement: 'bottomRight',
      duration: 2,
    });
  };

  const onChangeUserPoeStatus = async (
    id: string,
    type: string,
    reason: string,
    rejectTag: string,
  ) => {
    setUserPoePreviewOpen(false);
    setPreviewUserId(null);
    const { result } = await changeUserPoeStatus(id, type, reason, rejectTag);

    if (result?.status) {
      audit.onClick({ target: `${type}-user-poe` });
      refresh();

      notification.success({
        message: `User PoE request has been ${
          type === 'approve' ? 'approved' : 'rejected'
        }.`,
        placement: 'bottomRight',
        duration: 2,
      });

      refresh();

      return;
    }

    notification.error({
      message: `Failed to ${type} user PoE`,
      placement: 'bottomRight',
      duration: 2,
    });
  };

  const onViewUser = async (user: MobileUser) => {
    setPreviewUserId(user.id);
    setUserPreviewOpen(true);
  };

  const onViewUserPoe = async (user: MobileUser) => {
    setPreviewUserId(user.id);
    setUserPoePreviewOpen(true);
  };

  const getTableMenuItems = (
    user: MobileUser,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      {
        key: `user-menu-manage-${user.id}`,
        label: <Link to={`/mobile-user/${user.id}/manage`}>Manage</Link>,
        icon: <SiStackedit size={20} />,
      },

      {
        key: `user-menu-preview-${user.id}`,
        label: 'Quick View',
        icon: <GrView size={20} />,
        onClick: () => {
          audit.onClick({
            target: 'quick-view',
            data: {
              userId: user.id,
            },
          });
          onViewUser(user);
        },
      },
      {
        key: `user-menu-activity-${user.id}`,
        label: 'User Activity',
        icon: <LuActivity size={20} />,
        onClick: () => console.log('view'),
        disabled: true,
      },
    ];

    if (session.hasPermission('users.edit')) {
      items.push({
        key: `user-menu-update-risk-level-${user.id}`,
        label: 'Update Risk Level',
        icon: <BsAsterisk size={20} />,
        onClick: () => {
          setRiskLevelForm((prev) => ({
            ...prev,
            modalOpen: true,
            userId: user.id,
            originalRiskLevel: user.riskLevel,
            riskLevel: user.riskLevel,
          }));
        },
      });
      if (user.riskLevel !== 'high') {
        const isWhitelisted = user.isWhitelisted ? user.isWhitelisted : false;

        const whiteListTitle = !isWhitelisted
          ? 'Add into whitelist'
          : 'Remove from whitelist';
        const iconWhiteList = !isWhitelisted ? (
          <CheckCircleOutlined size={20} />
        ) : (
          <CloseCircleOutlined size={20} />
        );
        // items.push({
        //   key: `user-menu-update-white-list-${user.id}`,
        //   label: whiteListTitle,
        //   icon: iconWhiteList,
        //   onClick: () => {
        //     setWhitelistModalForm((prev) => ({
        //       ...prev,
        //       modalOpen: true,
        //       loading: false,
        //       userId: user.id,
        //       notes: '',
        //       modalTitle: !isWhitelisted
        //         ? 'Add user into whitelist'
        //         : 'Remove user from whitelist',
        //       executeOperation: !isWhitelisted ? 'write' : 'delete',
        //     }));
        //   },
        // });
        items.push({
          key: `user-menu-edit-${user.id}`,
          label: 'Edit',
          icon: <PiPencil size={20} />,
          onClick: () => console.log('view'),
          disabled: true,
        });

        items.push({
          key: `user-menu-change-store-${user.id}`,
          label: 'Change Store',
          icon: <TbExchange size={20} />,
          onClick: () => {
            setChangeStoreModalData({
              userId: user.id,
              storeId: user.storeId || user.store?.id,
            });
          },
        });
        items.push({
          key: `user-menu-resend-email-verification-${user.id}`,
          label: 'Confirm user',
          icon: <CheckCircleOutlined size={20} />,
          onClick: () => {
            setConfirmUserForm((prev) => ({
              ...prev,
              modalOpen: true,
              userId: user.id,
            }));
          },
        });
      }
      items.push({
        key: `user-menu-approval-preview-${user.id}`,
        label: 'PoE View',
        icon: <GoVerified size={20} />,
        onClick: () => {
          audit.onClick({
            target: 'poe-view',
            data: {
              userId: user.id,
            },
          });
          onViewUserPoe(user);
        },
      });
    }

    return items;
  };

  const onExecuteWhitelistModal = async () => {
    setWhitelistModalForm((prev) => ({ ...prev, loading: true }));

    const { userId, notes, executeOperation } = whitelistModalForm;

    if (executeOperation === 'write') {
      audit.onClick({ target: 'add-white-list-device-phone-user' });
      const { status } = await addWhitelistPhoneNumberToUser({
        userId,
        notes,
      });
      if (status) {
        notification.success({
          message: 'User added to white list successfully',
          placement: 'bottomRight',
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Failed to add user to white list',
          placement: 'bottomRight',
          duration: 2,
        });
      }
    } else if (executeOperation === 'delete') {
      audit.onClick({ target: 'remove-white-list-device-phone-user' });
      const { status } = await removeWhitelistPhoneNumberToUser({
        userId,
      });
      if (status) {
        notification.success({
          message: 'User removed from white list successfully',
          placement: 'bottomRight',
          duration: 2,
        });
      } else {
        notification.error({
          message: 'Failed to remove user from white list',
          placement: 'bottomRight',
          duration: 2,
        });
      }
    }
    setTimeout(() => {
      refresh();
      setWhitelistModalForm((prev) => ({
        ...prev,
        modalOpen: false,
        loading: true,
      }));
    }, 500);
  };

  const onConfirmUser = async () => {
    setConfirmUserForm((prev) => ({ ...prev, loading: true }));

    const { userId } = confirmUserForm;

    try {
      const returna = await adminConfirmUser(userId, navigate);
      audit.onClick({ target: 'confirm-user' });
      console.log(returna);

      notification.success({
        message: 'User was confirmed',
        placement: 'bottomRight',
        duration: 2,
      });
    } catch (Error) {
      notification.error({
        message: 'Failed to confirm the user',
        placement: 'bottomRight',
        duration: 2,
      });
    }

    setTimeout(() => {
      refresh();
      setConfirmUserForm((prev) => ({
        ...prev,
        modalOpen: false,
        loading: false,
        userId: '',
      }));
    }, 500);
  };

  const onChangeStore = () => {
    // The audit is done in the modal
    setChangeStoreModalData(null);
    refresh();
  };

  return (
    <>
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <When condition={mode === 'page'}>
          <UserStatistics />
          <Divider />
        </When>

        <Row gutter={16}>
          <Col span={16}>
            <Input.Search
              placeholder="Search by User ID, Name, Email, Store Name, Referral Code or Device ID"
              allowClear
              onSearch={(value) => {
                setFilters((prev) => ({
                  ...prev,
                  text: value,
                }));
                audit.onClick({
                  target: 'search-table',
                });
              }}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={8}>
            <Select
              allowClear
              showSearch
              style={{ width: '100%' }}
              placeholder="Showing All Users"
              optionFilterProp="children"
              defaultValue={'N'}
              onChange={(value) =>
                setFilters((prev: any) => ({ ...prev, isDeleted: value }))
              }
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={[
                {
                  value: 'Y',
                  label: 'Showing Deleted Users Only',
                },
                {
                  value: 'N',
                  label: 'Showing Active Users Only',
                },
              ]}
            />
          </Col>
        </Row>
        <Table
          {...tableProps}
          rowKey={'id'}
          tableLayout={'fixed'}
          className={'clickable'}
          onRow={(record: MobileUser) => ({
            onDoubleClick: () => onViewUser(record),
          })}
        >
          <Table.Column
            title="Name"
            dataIndex="fullName"
            key="fullName"
            sorter
            fixed="left"
            width={350}
            render={(text: any, record: MobileUser, index: number) => {
              return (
                <Space className="user-row">
                  <Avatar
                    src={record.avatarUrl}
                    style={{ width: 48, height: 48 }}
                    icon={<BiUser />}
                    shape="circle"
                    size={48}
                  />

                  <Space.Compact direction="vertical" className="info">
                    <Typography.Title level={5}>
                      {record.fullName || (
                        <span style={{ color: '#999' }}>(name not set)</span>
                      )}
                    </Typography.Title>
                    {record.email && (
                      <Typography.Text copyable style={{ fontSize: 13 }}>
                        {record.email}
                      </Typography.Text>
                    )}
                    {record.phoneNumber && (
                      <Typography.Text copyable style={{ fontSize: 13 }}>
                        {record.phoneNumber}
                      </Typography.Text>
                    )}
                    <Typography.Text
                      copyable={{
                        tooltips: ['Copy User Id', 'Copied!'],
                        text: record.id,
                        onCopy: () => {
                          audit.onClick({
                            target: 'copy-userId',
                            data: {
                              userId: record.id,
                            },
                          });
                        },
                      }}
                      style={{ fontSize: 13 }}
                    >
                      <Tag>{record.id}</Tag>
                    </Typography.Text>
                    {/* {record.referralCode && (
                      <Typography.Text
                        copyable={{
                          text: record.referralCode,
                          onCopy: () => {
                            audit.onClick({
                              target: 'copy-referralCode',
                              data: {
                                referralCode: record.referralCode,
                              },
                            });
                          },
                        }}
                      >
                        <code>{record.referralCode}</code>
                      </Typography.Text>
                    )} */}
                  </Space.Compact>
                </Space>
              );
            }}
          />

          {mode === 'page' && (
            <Table.Column
              title="Store"
              dataIndex="store.name"
              key="store.name"
              align="left"
              width={250}
              sorter
              ellipsis
              render={(_, record: MobileUser) => {
                const store = get(record, 'store', null);

                if (!store || !store.name) return '-';

                return (
                  <Space className="store-row">
                    <Space direction="vertical" className="info">
                      <Typography.Text ellipsis strong>
                        {store.name}
                      </Typography.Text>
                      <Typography.Text ellipsis className="address">
                        {get(store, 'address')}
                      </Typography.Text>
                    </Space>
                  </Space>
                );
              }}
            />
          )}

          <Table.Column
            title="Status"
            dataIndex="isActive"
            key="isActive"
            align="center"
            sorter
            render={(text: any, record: MobileUser, index: number) => {
              return record.isActive ? (
                <Tag color="blue">Active</Tag>
              ) : (
                <Tag color="red">Deleted</Tag>
              );
            }}
          />

          <Table.Column
            title="Confirmed"
            dataIndex="isConfirmed"
            key="isConfirmed"
            align="center"
            sorter
            render={(text: any, record: MobileUser, index: number) => {
              return record.isConfirmed ? (
                <Tag color="blue">Confirmed</Tag>
              ) : (
                <Tag color="grey">Not Confirmed</Tag>
              );
            }}
          />
          {/*<Table.Column*/}
          {/*  title="Role"*/}
          {/*  dataIndex="role"*/}
          {/*  key="role"*/}
          {/*  align="center"*/}
          {/*  sorter*/}
          {/*  render={(text: any, record: MobileUser, index: number) => {*/}
          {/*    return (*/}
          {/*      <Typography.Text ellipsis strong>*/}
          {/*        {startCase(record.role)}*/}
          {/*      </Typography.Text>*/}
          {/*    );*/}
          {/*  }}*/}
          {/*/>*/}
          <Table.Column
            title="Risk Level"
            dataIndex="riskLevel"
            key="riskLevel"
            align="center"
            sorter
            filters={[
              {
                text: 'Low',
                value: 'low',
              },
              {
                text: 'Medium',
                value: 'medium',
              },
              {
                text: 'High',
                value: 'high',
              },
            ]}
            render={(text: any, record: MobileUser, index: number) => {
              return (
                <Switch>
                  <Case condition={text === 'low'}>
                    <Tag color="success">Low</Tag>
                  </Case>
                  <Case condition={text === 'medium'}>
                    <Tag color="orange">Medium</Tag>
                  </Case>
                  <Case condition={text === 'high'}>
                    <Tag color="red">High</Tag>
                  </Case>
                </Switch>
              );
            }}
          />
          <Table.Column
            title="PoE Status"
            dataIndex="poeStatus"
            key="poeStatus"
            align="center"
            sorter
            filters={[
              {
                text: 'Approved',
                value: 'approved',
              },
              {
                text: 'Not Started',
                value: 'not_started',
              },
              {
                text: 'Pending',
                value: 'pending',
              },
              {
                text: 'Rejected',
                value: 'rejected',
              },
              {
                text: 'Rejection Limit Reached',
                value: 'rejected_limit',
              },
            ]}
            render={(text: any, record: MobileUser, index: number) => {
              return (
                <Switch>
                  <Case condition={text === 'approved'}>
                    <Tag color="success">Approved</Tag>
                  </Case>
                  <Case condition={text === 'not_started'}>
                    <Tag color="warning">Not Started</Tag>
                  </Case>
                  <Case condition={text === 'pending'}>
                    <Tag color="orange">Pending</Tag>
                  </Case>
                  <Case condition={text === 'rejected'}>
                    <Tag color="error">Rejected</Tag>
                  </Case>
                  <Case condition={text === 'rejected_limit'}>
                    <Tag color="red">Rejection Limit Reached</Tag>
                  </Case>
                </Switch>
              );
            }}
          />

          <Table.Column
            title="Updated At"
            dataIndex="updatedAt"
            key="updatedAt"
            align="center"
            sorter
            render={(text: any, record: MobileUser, index: number) => {
              if (!text) return '-';
              return (
                <Typography.Text ellipsis>
                  {formatISODate(text) || '-'}
                </Typography.Text>
              );
            }}
          />

          <Table.Column
            title="Actions"
            dataIndex="actions"
            key="actions"
            align="right"
            fixed="right"
            render={(text: any, record: MobileUser, index: number) => {
              return (
                <Dropdown menu={{ items: getTableMenuItems(record) }}>
                  <Button>
                    <PiDotsThreeOutline size={20} />
                  </Button>
                </Dropdown>
              );
            }}
          />
        </Table>
      </Space>

      <DrawerQuickView
        visible={userPreviewOpen}
        userId={previewUserId}
        onClose={() => {
          setUserPreviewOpen(false);
          setPreviewUserId(null);
        }}
        onDeleteUser={onDeleteUser}
      />
      <DrawerPoeView
        visible={userPoePreviewOpen}
        userId={previewUserId}
        onClose={() => {
          setUserPoePreviewOpen(false);
          setPreviewUserId(null);
        }}
        onChangeUserPoeStatus={onChangeUserPoeStatus}
      />
      <Modal
        title="Update Risk Level"
        open={riskLevelForm.modalOpen}
        okButtonProps={{
          disabled:
            riskLevelForm.riskLevel === riskLevelForm.originalRiskLevel ||
            riskLevelForm.riskLevel === '',
          loading: riskLevelForm.isLoading,
        }}
        onOk={onUpdateRiskLevel}
        onCancel={() => {
          setRiskLevelForm((prev) => ({ ...prev, modalOpen: false }));
        }}
        destroyOnClose={true}
      >
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Typography.Text>Select the risk level for this user</Typography.Text>

          <RiskLevelSwitch
            checkedChildren="High"
            unCheckedChildren="Low"
            defaultChecked={riskLevelForm.riskLevel === 'high'}
            onChange={(checked) => {
              setRiskLevelForm((prev) => ({
                ...prev,
                riskLevel: checked ? 'high' : 'low',
              }));
            }}
          />

          <When
            condition={
              riskLevelForm.originalRiskLevel !== riskLevelForm.riskLevel &&
              riskLevelForm.riskLevel === 'high'
            }
          >
            <RiskLevelHighAlert
              message={
                <>
                  Changing Risk level to <strong>High</strong> entails:
                </>
              }
              description={
                <ul>
                  <li>Cancellation of all activities.</li>
                  <li>Transfer to a Flagged store.</li>
                  <li>Removal all withdrawal requests.</li>
                  <li>
                    Rejection of pending completions and pending earnings.
                  </li>
                  <li>Removal of Pig Bank Balance.</li>
                  <li>Block all user devices</li>
                </ul>
              }
              type="warning"
              showIcon
            />
          </When>
        </Space>
      </Modal>

      <Modal
        title={whitelistModalForm.modalTitle}
        open={whitelistModalForm.modalOpen}
        centered
        okButtonProps={{
          loading: whitelistModalForm.loading,
        }}
        afterOpenChange={(open) => {
          if (!open) {
            form.resetFields();
          }
        }}
        onOk={onExecuteWhitelistModal}
        onCancel={() => {
          setWhitelistModalForm((prev) => ({
            ...prev,
            modalOpen: false,
            notes: '',
          }));
        }}
        destroyOnClose={true}
      >
        <Form layout="vertical" autoComplete="off" form={form}>
          {whitelistModalForm.executeOperation === 'write' && (
            <>
              <RiskLevelHighAlert
                message={
                  <>
                    Add user into <strong>white list</strong> entails:
                  </>
                }
                description={
                  <ul>
                    <li>
                      Add all devices (uid) linked to this user to devices{' '}
                      <strong>white list</strong> .
                    </li>
                    <li>
                      If the user has a registered phone number, it will be
                      added to phones <strong>white list</strong> .
                    </li>
                  </ul>
                }
                type="warning"
                showIcon
              />
              <br />
              <Form.Item
                name="notes"
                label="Notes"
                rules={[{ required: true }]}
              >
                <Input.TextArea
                  value={whitelistModalForm.notes}
                  onChange={({ target }) =>
                    setWhitelistModalForm((prev) => ({
                      ...prev,
                      notes: target?.value,
                    }))
                  }
                  rows={4}
                />
              </Form.Item>
            </>
          )}
          {whitelistModalForm.executeOperation === 'delete' && (
            <RiskLevelHighAlert
              message={
                <>
                  Remove user from <strong>white list</strong> entails:
                </>
              }
              description={
                <ul>
                  <li>
                    All devices (uid) linked to this user from devices{' '}
                    <strong>white list</strong> will be removed.
                  </li>
                  <li>
                    If the user has a registered phone number, it will be
                    deleted from phones <strong>white list</strong>
                  </li>
                </ul>
              }
              type="warning"
              showIcon
            />
          )}
        </Form>
      </Modal>

      <Modal
        title="Confirm Usern"
        open={confirmUserForm.modalOpen}
        okButtonProps={{
          loading: confirmUserForm.loading,
        }}
        onOk={onConfirmUser}
        onCancel={() => {
          setConfirmUserForm((prev) => ({
            ...prev,
            modalOpen: false,
          }));
        }}
        destroyOnClose={true}
      >
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Typography.Text>Confirm the user?</Typography.Text>
        </Space>
      </Modal>

      <ChangeStoreModal
        userId={changeStoreModalData?.userId}
        storeId={changeStoreModalData?.storeId}
        onChangeStore={onChangeStore}
        onCancel={() => setChangeStoreModalData(null)}
      />
    </>
  );
};

export default MobileUserList;
