import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { Brand } from '@/types';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Switch, Table, Tag } from 'antd';
import { useEffect, useState } from 'react';
import ModalForm from './modal-form';
import { BrandPlan } from './types';
import { formatMoney } from '@/utils';
import { startCase } from 'lodash';

const PlanComponent = () => {
  const [filter, setFilter] = useState('');
  const [isOpenForm, setOpenForm] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<BrandPlan | null>(null);

  const { tableProps, refresh } = useTable<Brand>({
    service: 'brand/read',
    path: `admin/plan/list`,
    params: {
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'updatedAt',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'brand-plan',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  return (
    <Layout noCard={false}>
      <PageHeader noBackButton title="Brand Plans">
        <Button
          type="primary"
          onClick={() => {
            setCurrentPlan(null);
            setTimeout(() => setOpenForm(true), 0);
          }}
          icon={<PlusOutlined />}
        >
          Add Plan
        </Button>
      </PageHeader>

      <TableContainer>
        <Table {...tableProps} rowKey={'id'}>
          <Table.Column title="Brand Name" dataIndex="name" key="name" sorter />
          <Table.Column
            title="Plan Type"
            dataIndex="planType"
            key="planType"
            render={(val) => startCase(val)}
          />
          <Table.Column
            title="Monthly Price"
            dataIndex="monthlyPrice"
            key="monthlyPrice"
            sorter
            render={(val) => {
              if (!val) return '-';
              return formatMoney(val);
            }}
          />
          <Table.Column
            title="Extra Per Submission Price"
            dataIndex="extraPerSubmissionPrice"
            key="extraPerSubmissionPrice"
            sorter
            render={(val) => {
              if (!val) return '-';
              return formatMoney(val);
            }}
          />
          <Table.Column
            title="Status"
            dataIndex="isActive"
            key="isActive"
            align="center"
            sorter
            render={(text: any, record: BrandPlan, index: number) => {
              if (record.isActive) {
                return <Tag color="success">Active</Tag>;
              }

              return <Tag color="error">Inactive</Tag>;
            }}
          />
          <Table.Column
            title=""
            dataIndex="actions"
            key="actions"
            align="right"
            render={(text: any, record: BrandPlan, index: number) => {
              return (
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      setCurrentPlan(record);
                      setOpenForm(true);
                    }}
                  >
                    Edit
                  </Button>
                </Space>
              );
            }}
          />
        </Table>
      </TableContainer>
      <ModalForm
        isOpen={isOpenForm}
        onClose={setOpenForm}
        refrsh={refresh}
        data={currentPlan}
      />
    </Layout>
  );
};

export default PlanComponent;
