import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import {
  Avatar,
  Badge,
  Button,
  Descriptions,
  Modal,
  Space,
  Tabs,
  Tag,
  Typography,
  DatePicker,
  Popover,
  Input,
  Divider,
  notification
} from 'antd';
import { UserPageHeader, AdvancedFilterContent } from './styles';
import { TabsContainer, TabsControlContainer } from '@/styles/tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Brand } from '@/types';
import { formatISODate, formatMoney } from '@/utils';
import { Switch, Case, When } from 'react-if';
import { useAudit } from '@/hooks';
import { map, startCase } from 'lodash';
import { getBrandDetails } from '@/services/brand.service';
import { IoPlanetOutline } from 'react-icons/io5';
import SegmentTag from '../components/SegmentTag';
import TagsRender from '../../../components/TagsRender';
import { PiPencilLineBold } from 'react-icons/pi';

import { ButtonLink } from '@/components/ButtonLink';

/* TABS */
import WalletTab from './WalletTab';
import MembersTab from './MembersTab';
import { TbTools } from 'react-icons/tb';
import { MdStorefront } from "react-icons/md";
import type { DatePickerProps } from 'antd';
import { syncStoresBrandsForRange } from "@/services/store.service";
import { Dayjs } from 'dayjs';

const Details = () => {
  const { brandId = '' } = useParams<{ brandId: string }>();
  const [brand, setBrand] = useState<Brand | null>(null);
  const [exportToolsModalVisible, setExportToolsModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState('tab-members');
  const navigate = useNavigate();

  const audit = useAudit({
    resourceName: 'brand-manage',
  });

  useEffect(() => {
    audit.onAccess();
    loadBrandDetails();
  }, []);

  const loadBrandDetails = async () => {
    if (!brandId) return;

    const { brand } = await getBrandDetails(brandId);

    if (!brand) {
      navigate('/brand');
    }

    setBrand(brand);
  };

  const [syncModal, setSyncModal] = useState<{
    open: boolean,
    storeId: string,
    startDate: Date | null,
    endDate: Date | null,
  }>({
    open: false,
    storeId:'',
    startDate: null,
    endDate: null,
  });
  
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const onChangeMonth: DatePickerProps['onChange'] = (date, dateString) => {
    setSelectedDate(date);
    if (date) {
      const startDate = new Date(date.year(), date.month(), 1);
      const endDate = new Date(date.year(), date.month() + 1, 1);

      setSyncModal((prev) => ({
        ...prev,
          startDate,
          endDate,
      }));
    } else {
      setSyncModal((prev) => ({
        ...prev,
          startDate: null,
          endDate: null,
      }));
    }  
  };

  const syncStoresBrandsForRangeHandler = async () => {
    const {status, message} = await syncStoresBrandsForRange(syncModal.storeId, brandId, syncModal.startDate, syncModal.endDate);

    if(status) {
      notification.success({
        message: 'Success',
        description: message,
      });
    } else {
      notification.error({
        message: 'Error',
        description: message,
      });
    }

    setSyncModal({storeId: '', startDate: null, endDate: null, open: false});
    setSelectedDate(null);
  }
  
  return (
    <Layout>
      <PageHeader title="Brand Manage">
        <Space>
          <Popover
            content={
              <AdvancedFilterContent>
                <section>
                  <Typography.Text strong>Month when missing stores followed this brand</Typography.Text>
                  <DatePicker 
                    onChange={onChangeMonth}
                    picker="month" 
                    style={{width:'100%'}}
                    value={selectedDate}
                  />
                </section>

                <Divider>
                  OR
                </Divider>

                <section>
                  <Typography.Text strong>Missing Store ID</Typography.Text>
                  <Input
                    placeholder="e.g.: 5d49f2eed3e0963f3e9e3242"
                    value = { syncModal.storeId }
                    onChange = {(e) =>
                      setSyncModal((prev) => ({
                        ...prev,
                          storeId: e.target.value,
                      }))
                    }
                  />
                </section>

                <div className="footer">
                  <Button
                    type={'link'}
                    onClick={() => {
                      setSyncModal((prev) => ({
                        ...prev,
                        storeId:'',
                        startDate:null,
                        endDate:null
                      }));
                      setSelectedDate(null);
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      syncStoresBrandsForRangeHandler();
                    }}
                  >
                    Sync
                  </Button>
                </div>
              </AdvancedFilterContent>
            }
            trigger="click"
            open={syncModal.open}
            onOpenChange={(open) => {
              setSyncModal((prev) => ({ ...prev, open }));
            }}
          >
            <Button 
              icon={<MdStorefront /> }
            >
              Sync Stores
            </Button>
          </Popover>

          <ButtonLink
            to={`/brand/${brandId}/edit`}
            icon={<PiPencilLineBold />}
            type="primary"
          >
            Edit Brand
          </ButtonLink>
          <Button
            icon={<TbTools />}
            color="#ff8000"
            onClick={() => setExportToolsModalVisible(true)}
          >
            Export Tools
          </Button>
        </Space>
      </PageHeader>

      <UserPageHeader>
        <div className="main-info">
          <div className="avatar">
            <Badge.Ribbon
              style={{ display: brand?.isInactive ? 'block' : 'none' }}
              color={'red'}
              text={'Inactive'}
            >
              <Avatar
                size={200}
                src={brand?.logoUrl}
                shape="square"
                icon={<IoPlanetOutline />}
              />
            </Badge.Ribbon>
          </div>
          <div className="info">
            <div className="name-container">
              <Typography.Title level={2}>{brand?.name}</Typography.Title>
              <Descriptions>
                <Descriptions.Item label="ID">
                  <Typography.Text copyable>{brand?.id}</Typography.Text>
                </Descriptions.Item>
              </Descriptions>
            </div>

            <div className="details">
              <Descriptions layout="vertical">
                <Descriptions.Item label="Segment">
                  <SegmentTag segment={brand?.segment} />
                </Descriptions.Item>
                <Descriptions.Item label="Industries">
                  <When condition={brand?.industries?.length === 0}>
                    <Tag style={{ borderStyle: 'dashed' }}>None</Tag>
                  </When>
                  <When
                    condition={brand?.industries && brand.industries.length > 0}
                  >
                    <TagsRender
                      items={map(
                        brand?.industries,
                        (industry) => industry.name,
                      )}
                    />
                  </When>
                </Descriptions.Item>
                <Descriptions.Item label="Registration Date">
                  {formatISODate(
                    new Date((brand?.createdAt || 0) * 1000).toISOString(),
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Language / Currency">
                  {brand?.language || 'en'} / {brand?.currency || 'USD'}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Plan">
                  {startCase(brand?.settingsPlan)}
                </Descriptions.Item>
                <Descriptions.Item label="Smart Review">
                  <Switch>
                    <Case condition={brand?.smartReviewEnabled}>
                      {startCase(brand?.smartReviewFrequency)} /{' '}
                      {formatMoney(brand?.smartReviewPrice || 0)}
                    </Case>
                    <Case condition={!brand?.smartReviewEnabled}>
                      <Tag style={{ borderStyle: 'dashed' }}>Disabled</Tag>
                    </Case>
                  </Switch>
                </Descriptions.Item>
                <Descriptions.Item label="Countries">
                  <When condition={brand?.countries?.length === 0}>
                    <Tag style={{ borderStyle: 'dashed' }}>None</Tag>
                  </When>
                  <When
                    condition={brand?.countries && brand.countries.length > 0}
                  >
                    <TagsRender items={brand?.countries || []} />
                  </When>
                </Descriptions.Item>

                <Descriptions.Item label="Prizes">
                  {Number(brand?.prizePercentage || 0).toFixed(1)}%
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </div>
      </UserPageHeader>

      <TabsControlContainer>
        <Tabs
          items={[
            {
              key: 'tab-members',
              label: 'Members (EFR / EFB)',
            },
            {
              key: 'tab-wallet',
              label: 'Wallet',
            },
            {
              key: 'tab-payments',
              label: 'Payments',
              disabled: true,
            },
            {
              key: 'tab-prize',
              label: 'Prizes',
              disabled: true,
            },
          ]}
          onChange={(tab: string) => setCurrentTab(tab)}
        />
      </TabsControlContainer>

      <TabsContainer>
        <Switch>
          <Case condition={currentTab === 'tab-wallet' && brand !== null}>
            <WalletTab
              brand={brand}
              onRefresh={() => {
                loadBrandDetails();
              }}
            />
          </Case>
          <Case condition={currentTab === 'tab-members' && brand !== null}>
            <MembersTab
              brand={brand}
              onRefresh={() => {
                loadBrandDetails();
              }}
            />
          </Case>
        </Switch>
      </TabsContainer>

      <Modal
        title="Export Tools"
        open={exportToolsModalVisible}
        onCancel={() => setExportToolsModalVisible(false)}
        footer={null}
        width={300}
      >
        <br />
        <Space direction="vertical" style={{ width: '100%' }}>
          <Button block size="large">
            Completions
          </Button>
          <Button block size="large">
            Stores
          </Button>

          <Button block size="large">
            Payments
          </Button>

          <Button block size="large">
            Groups
          </Button>

          <Button block size="large">
            Store by Groups
          </Button>

          <Button block size="large">
            Codes
          </Button>

          <Button block size="large">
            Transactions
          </Button>
        </Space>
      </Modal>
    </Layout>
  );
};

export default Details;
