import { DetailedManagerSubmission } from '@/types';
import { httpGet, httpPost } from './util/axios';

export const getStatusSummary = async () => {
  return httpGet('completion/read', `admin/sales-incentive/status-summary`);
};

export const requestSalesIncentiveApprovals = async (payload: {
  completionsId: string[];
  status: string;
  rejectionArgs?: any;
  lang?: string;
}) => {
  return httpPost(
    'completion/write',
    `admin/sales-incentive/request-approval`,
    payload,
  );
};

export const saveCompletionComment = async (
  completionId: string,
  comment: string,
) => {
  return httpPost(
    'completion/write',
    `admin/sales-incentive/completion/${completionId}/comment`,
    {
      comment,
    },
  );
};

export const getCompletionComments = async (completionId: string) => {
  return httpGet(
    'completion/read',
    `admin/sales-incentive/completion/${completionId}/comment`,
  );
};

export const saveCampaignApprovalNote = async (
  campaignId: string,
  note: string,
) => {
  return httpPost(
    'completion/write',
    `admin/sales-incentive/campaign/${campaignId}/approval-note`,
    {
      note,
    },
  );
};

export const getCompletionsById = async (completionsId: string[]) => {
  return httpGet(
    'completion/read',
    `admin/sales-incentive/completion/get-many?completionsId=${completionsId.join(
      ',',
    )}`,
  );
};

export const updateSalesIncentiveSalesAmount = async (payload: any) => {
  try {
    return httpPost(
      'completion/write',
      `admin/sales-incentive/update-sales-amount`,
      {
        ...payload,
      },
    );
  } catch (error) {
    return false;
  }
};

export const updateSalesIncentiveMultipleChoiceSold = async (payload: any) => {
  try {
    return httpPost(
      'completion/write',
      `admin/sales-incentive/update-item-sold`,
      {
        ...payload,
      },
    );
  } catch (error) {
    return false;
  }
};

export const getManagerSubmissionsStatusSummary = async () => {
  return httpGet(
    'completion/read',
    `admin/sales-incentive/manager-status-summary`,
  );
};

export const getDetailedManagerSubmission = async (submissionId: string) => {
  return (await httpGet(
    'completion/read',
    `admin/sales-incentive/detailed-manager-submission/${submissionId}`,
  )) as
    | { status: false; message: string }
    | { result: DetailedManagerSubmission };
};

export const approveManagerSubmissions = async (payload: {
  submissionsIds: string[];
}) => {
  return (await httpPost(
    'completion/write',
    'admin/sales-incentive/approve-brand-submissions',
    payload,
  )) as { status: boolean; success: number; failed: number };
};

export const rejectManagerSubmissions = async (payload: {
  submissionsIds: string[];
  rejectionArgs?: { rejectBy: string; rejectText: string };
}) => {
  return (await httpPost(
    'completion/write',
    'admin/sales-incentive/reject-brand-submissions',
    payload,
  )) as { status: boolean; success: number; failed: number };
};

export const revertRejection = async (completionId: string) => {
  try {
    const { status } = await httpPost(
      'completion/write',
      `admin/sales-incentive/revert-rejection`,
      { completionId },
    );

    return { status };
  } catch (error) {
    return { status: false };
  }
};

export const getSubmissionCompletions = async (
  startDate: string,
  endDate: string,
): Promise<any[]> => {
  const { items = [] } = await httpGet(
    'completion/read',
    `admin/sales-incentive/submission-completions?startDate=${startDate}&endDate=${endDate}`,
  );

  return items;
};
