import { createGlobalStyle } from 'styled-components';
import 'antd/dist/reset.css';

export default createGlobalStyle` 
  #root {
    > .ant-layout {
      min-height: 100vh;
    }
  }
  
  .table-footer-drawer {
    position: sticky;
    width: 100%;
    bottom: 0;
    
    .ant-drawer-content-wrapper {
      box-shadow: 0px -8px 8px 0px #0000000D;
      
      .ant-drawer-body {
        display: flex;
        align-items: center;
        padding: 0 24px;
        justify-content: space-between;
      }
    }
  }

  .ant-modal-root {
    
  }

  .store-group-modal-selector {
    .ant-modal-wrap {
      .ant-modal {
        .ant-modal-content {
          .ant-modal-header {
            text-align: center;
            .ant-modal-title {
              font-size: 24px;
            }
          }
        }
      }
    }
    .ant-modal-header {
      font-size: 32px;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`;
