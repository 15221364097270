import BrandPickerModal from '@/components/BrandPickerModal';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import { notifyRetailers } from '@/services/campaign.service';
import { useSession } from '@/store';
import { TableContainer } from '@/styles';
import { Brand, CampaignListItem } from '@/types';
import { formatISODate } from '@/utils';
import {
  Avatar,
  Button,
  DatePicker,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Space,
  Table,
  Typography,
} from 'antd';
import { MessageSquareDot } from 'lucide-react';
import { useEffect, useState } from 'react';
import { PiDotsThreeOutline } from 'react-icons/pi';
import { When } from 'react-if';
import TypeTag from './TypeTag';
import { PageContainer, TableDateFilterContent } from './styles';

const Page = () => {
  const session = useSession();
  const [textFilter, setTextFilter] = useState('');
  const [status, setStatus] = useState('live');
  const [brandFilterModal, setBrandFilterModal] = useState({
    open: false,
    brand: null as Brand | null,
    brandId: '',
  });

  const [dateFilter, setDateFilter] = useState<any>({
    temp: {
      startDate: '',
      endDate: '',
    },
    value: {
      startDate: '',
      endDate: '',
    },
    componentCount: 0,
  });

  const [modal, contextHolder] = Modal.useModal();

  const { tableProps, refresh } = useTable<CampaignListItem>({
    service: 'campaign/read',
    path: `admin/list`,
    params: {
      filters: {
        text: textFilter,
        brandId: brandFilterModal.brandId,
        launchDate: dateFilter.value,
      },
      status,
    },
    defaultSort: {
      field: 'launchedAt',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'campaign',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const getTableMenuItems = (
    campaign: CampaignListItem,
  ): NonNullable<MenuProps['items']> => {
    const items: NonNullable<MenuProps['items']> = [
      // {
      //   key: `store-menu-manage-${campaign.id}`,
      //   label: <Link to={`/store/${campaign.id}/manage`}>Manage</Link>,
      //   icon: <SiStackedit size={20} />,
      // },
    ];

    if (session.hasPermission('campaigns.edit') && campaign.status === 'live') {
      items.push({
        key: `campaign-send-notification-menu-${campaign.id}`,
        label: 'Send "New Campaign" Notification',
        icon: <MessageSquareDot />,
        onClick: async () => {
          await modal.confirm({
            title: `Confirm Action`,
            content: `
              Are you sure you want to notify users about the campaign "${campaign.name}"?
            `,
            okText: 'Confirm',
            cancelText: 'Cancel',
            async onOk(...args) {
              audit.onClick({
                target: 'Send "New Campaign" Notification',
                data: {
                  campaignId: campaign.id,
                },
              });

              const { status = false } = await notifyRetailers(
                'new_campaign',
                campaign.id,
              );

              if (status) {
                modal.success({
                  title: 'Done!',
                  content: 'The notification was successfully sent.',
                });
              } else {
                modal.error({
                  title: 'Error',
                  content: 'An error occurred while sending the notification.',
                });
              }
            },
          });
        },
      });
    }

    return items;
  };

  return (
    <>
      <Layout>
        <PageHeader noBackButton title="Campaigns" />

        <PageContainer>
          <div className="table-filter-header">
            <Space>
              <Button
                type={status === 'live' ? 'primary' : 'default'}
                onClick={() => setStatus('live')}
              >
                Live
              </Button>
              <Button
                type={status === 'finished' ? 'primary' : 'default'}
                onClick={() => setStatus('finished')}
              >
                Finished
              </Button>
            </Space>

            <Space>
              <Button
                title="Filter by Brand"
                onClick={() =>
                  setBrandFilterModal((prev) => ({ ...prev, open: true }))
                }
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <When condition={!!brandFilterModal.brand}>
                  <Space>
                    <Avatar
                      src={brandFilterModal.brand?.logoUrl}
                      size={20}
                      shape="square"
                    />
                    <Typography.Text strong style={{ fontSize: 12 }}>
                      {brandFilterModal.brand?.name}
                    </Typography.Text>

                    <Button
                      type={'link'}
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setBrandFilterModal((prev) => ({
                          ...prev,
                          brand: null,
                          brandId: '',
                        }));
                      }}
                    >
                      Clear
                    </Button>
                  </Space>
                </When>
                <When condition={!brandFilterModal.brand}>Filter by Brand</When>
              </Button>
              <Input.Search
                className="search-input"
                placeholder="Search by campaign name or mission name"
                allowClear
                onSearch={setTextFilter}
              />
            </Space>
          </div>

          <br />

          <TableContainer>
            <Table
              {...tableProps}
              showHeader={true}
              rowKey={'id'}
              // onChange={(...data) => {
              //   console.log('Table onChange', data);
              // }}
              // expandable={{
              //   rowExpandable: (record: CampaignListItem) => true,
              //   expandedRowRender: (record: CampaignListItem) => {
              //     return <div />;
              //   },
              // }}
            >
              <Table.Column
                title="Campaign Name"
                key="name"
                dataIndex={'name'}
                align="left"
                sorter
                ellipsis
                fixed="left"
              />

              <Table.Column
                title="Type"
                width={150}
                dataIndex={'type'}
                align="center"
                sorter
                ellipsis
                filterMode="tree"
                filters={[
                  {
                    text: 'General',
                    value: 'general',
                  },
                  {
                    text: 'Contest',
                    value: 'contest',
                  },
                  {
                    text: 'Learning',
                    value: 'learning',
                  },
                  {
                    text: 'Consumer',
                    value: 'consumer',
                  },
                ]}
                render={(_, record: CampaignListItem) => {
                  return <TypeTag type={record.type} />;
                }}
              />

              <Table.Column
                title="Brand"
                dataIndex={'brand.name'}
                sorter
                render={(_, record: CampaignListItem) => {
                  if (!record.brand) {
                    return <span className="inactive-text">(not set)</span>;
                  }

                  return (
                    <Space>
                      <Avatar
                        src={record.brand.logoUrl}
                        size={45}
                        shape="square"
                      />
                      <Space.Compact direction={'vertical'}>
                        <Typography.Text strong>
                          {record.brand.name}
                        </Typography.Text>
                      </Space.Compact>
                    </Space>
                  );
                }}
              />

              <Table.Column
                title="Users"
                width={100}
                key="reach.users"
                dataIndex={'reach.users'}
                align="center"
                sorter
                render={(_, record: CampaignListItem) => (
                  <Typography.Text strong>
                    {record.reach.users || 0}
                  </Typography.Text>
                )}
              />

              <Table.Column
                title="Stores"
                width={100}
                key="reach.stores"
                dataIndex={'reach.stores'}
                align="center"
                sorter
                render={(_, record: CampaignListItem) => (
                  <Typography.Text strong>
                    {record.reach.stores || 0}
                  </Typography.Text>
                )}
              />

              <Table.Column
                title="Launched At"
                key="launchedAt"
                dataIndex={'launchedAt'}
                sorter
                filterDropdown={({ confirm }) => {
                  return (
                    <TableDateFilterContent>
                      <div className="title-filter">Filter by date range</div>
                      <DatePicker.RangePicker
                        key={`datepicker-range-${dateFilter.componentCount}`}
                        onChange={(dates, datesString: string[]) => {
                          setDateFilter((prev: any) => ({
                            ...prev,
                            temp: {
                              startDate: datesString[0],
                              endDate: datesString[1],
                            },
                          }));
                        }}
                      />

                      <div className="actions">
                        <Button
                          size="small"
                          onClick={() => {
                            setDateFilter((prev: any) => ({
                              ...prev,
                              temp: {
                                startDate: '',
                                endDate: '',
                              },
                              value: {
                                startDate: '',
                                endDate: '',
                              },
                              componentCount: prev.componentCount + 1,
                            }));
                          }}
                        >
                          Reset
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() => {
                            setDateFilter((prev: any) => ({
                              ...prev,
                              value: prev.temp,
                            }));
                            confirm();
                          }}
                        >
                          Apply
                        </Button>
                      </div>
                    </TableDateFilterContent>
                  );
                }}
                render={(_, record: CampaignListItem) =>
                  formatISODate(record.launchedAt)
                }
              />

              {status === 'finished' && (
                <Table.Column
                  title="Finished At"
                  key="finishedAt"
                  sorter
                  render={(_, record: CampaignListItem) =>
                    formatISODate(record.finishedAt)
                  }
                />
              )}

              <Table.Column
                title="Actions"
                dataIndex="actions"
                key="actions"
                align="right"
                fixed="right"
                width={100}
                render={(
                  text: any,
                  record: CampaignListItem,
                  index: number,
                ) => {
                  const items = getTableMenuItems(record);

                  if (items.length === 0) {
                    return null;
                  }

                  return (
                    <Dropdown menu={{ items }}>
                      <Button>
                        <PiDotsThreeOutline size={20} />
                      </Button>
                    </Dropdown>
                  );
                }}
              />
            </Table>
          </TableContainer>
        </PageContainer>
      </Layout>
      <BrandPickerModal
        open={brandFilterModal.open}
        onClose={() =>
          setBrandFilterModal((prev) => ({ ...prev, open: false }))
        }
        selected={[brandFilterModal.brandId]}
        multiple={false}
        onSelect={(brandsId, brands) => {
          setBrandFilterModal((prev) => ({
            ...prev,
            brand: brands[0] || null,
            brandId: brandsId[0] || '',
          }));
        }}
        removeSelected={false}
      />
      {contextHolder}
    </>
  );
};

export default Page;
