export const sectors = [
  {
    id: 'businessDevelopment',
    name: 'Business Development',
  },
  {
    id: 'clientSuccess',
    name: 'Client Success',
  },
  {
    id: 'customerSupport',
    name: 'Customer Support',
  },
  {
    id: 'finance',
    name: 'Finance',
  },
  {
    id: 'it',
    name: 'IT',
  },
  {
    id: 'marketing',
    name: 'Marketing',
  },
  {
    id: 'operations',
    name: 'Operations',
  },
  {
    id: 'product',
    name: 'Product',
  },
];

export const basePermissions: { [key: string]: string[] } = {
  brands: ['view', 'edit'],
  companies: ['view', 'edit'],
  stores: ['view', 'edit'],
  campaigns: ['view', 'edit'],
  users: ['view', 'edit'],
  admin_user: ['view', 'edit'],
  web_user: ['view', 'edit'],
  queue: ['view', 'edit'],
  billing: ['view', 'edit'],
  report: ['view', 'edit'],
  consumer_completion: ['view', 'edit'],
  sales_incentive_completion: ['view', 'edit'],
  integration: ['view', 'edit'],
  messaging_controler: ['view', 'edit'],
  cashout_request: ['view', 'edit'],
  brand_plans: ['view', 'edit'],
  countries: ['view', 'edit'],
  import: ['view', 'edit'],
};
