import styled from 'styled-components';

export const TabContainer = styled.div`
  .card-wallet-totals {
    .node-title {
      color: #ababab;
      font-size: 16px;
      font-weight: 500;

      i {
        margin-right: 4px;
      }
    }

    .node-amount {
      width: 100%;
      font-size: 32px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .pending {
        display: flex;
        flex-direction: column;
        text-align: right;

        > span {
          font-size: 10px;
          color: #ababab;
        }

        > strong {
          font-size: 16px;
          color: #777777;
        }
      }
    }

    .node-footer {
      margin-top: auto;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .actions {
        display: flex;
        column-gap: 16px;
        width: 100%;
      }

      .kpis {
        display: flex;
        justify-content: space-between;
        width: 100%;

        > div {
          display: flex;
          flex-direction: column;
          line-height: 130%;

          span {
            font-size: 12px;
            color: #ababab;
            font-weight: 700;
          }

          strong {
            color: #343640;
            font-size: 18px;
          }

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .transaction-column-type {
    display: inline-flex;
    column-gap: 8px;
    align-items: flex-start;

    .icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
      color: #ababab;
    }

    .icon-info {
      width: 15px;
      height: 15px;
      font-size: 15px;
      color: #ababab;
    }

    .info {
      width: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      line-height: 100%;
      justify-content: center;
      row-gap: 2px;

      > span {
        line-height: 100%;
        &:first-child {
          color: #343640;
          font-size: 16px;
          font-weight: 700;
        }

        &:last-child {
          color: #ababab;
          font-size: 12px;
        }
      }
    }
  }

  .transaction-column-date {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    line-height: 100%;
    row-gap: 2px;
  }
`;
