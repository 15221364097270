import styled from 'styled-components';

export const CustomSectionHeader = styled.div`
  display: flex;
  gap: 16px;

  .ant-divider {
    flex: 1;

    min-width: calc(100% - 130px);
  }

  .ant-form-item {
    flex: 1;
  }
`;
