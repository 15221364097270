import styled from 'styled-components';

export const BrandsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
`;

export const BrandCellContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 11px;
  font-weight: 500;

  > img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  > button {
    font-size: 11px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: 700;
  }
`;
