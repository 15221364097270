import { httpPut, httpGet, httpPost, handleError } from './util/axios';
import { Store } from '@/types';

export const getStoreDetails = async (
  storeId: string,
): Promise<{ store: Store } | null> => {
  return httpGet('store/read', `admin/${storeId}/details`);
};

export const getStoreFormData = async (storeId: string) => {
  return httpGet('store/read', `admin/${storeId}/form-data`);
};

export const updateStore = async (storeId: string, data: any) => {
  try {
    return (await httpPut('store/write', `admin/${storeId}/update`, data)) as
      | { status: false; message: string }
      | { status: 'partial'; storeId: string; message: string }
      | { status: true; storeId: string };
  } catch (error) {
    handleError(error);
  }
};

export const createDraftStore = async () => {
  return httpPost('store/write', `admin/create-draft`, {});
};

export const syncStoresBrandsForRange = async (
  storeId: string,
  brandId: string,
  startDate: Date | null,
  endDate: Date | null,
) => {
  try {
    return httpPost('store/write', `admin/update-store-brands-date-range`, {
      storeId,
      brandId,
      startDate,
      endDate,
    });
  } catch (error) {
    return { status: false, message: 'Something went wrong' };
  }
};

export type UpdateBrandsAssignmentAction = 'approve' | 'block' | 'unlink';

export const updateBrandsAssignment = async ({
  action,
  storeId,
  brandIds,
}: {
  action: UpdateBrandsAssignmentAction;
  storeId: string;
  brandIds: string[];
}) => {
  try {
    return (await httpPost('store/write', 'admin/update-brands-assignment', {
      action,
      storeId,
      brandIds,
    })) as
      | {
          status: false;
          message: string;
        }
      | {
          status: boolean;
          success: number;
          failed: number;
        };
  } catch (error) {
    handleError(error);
    return null;
  }
};

export const createBrandPlan = async (data: any) => {
  return httpPost('brand/write', `admin/plan/create`, data);
};

export const updateBrandPlan = async (id: string, data: any) => {
  return httpPut('brand/write', `admin/plan/${id}/update`, data);
};

export const updatePlanActiveStatus = async (id: string, isActive: boolean) => {
  return httpPut('brand/write', `admin/plan/${id}/active`, {
    isActive,
  });
};
