import { hookstate, useHookstate } from '@hookstate/core';
import type { DetailedManagerSubmission, ManagerSubmission } from '@/types';
import { getDetailedManagerSubmission } from '@/services/sales-incentive.service';
import { notification } from 'antd';

type DialogState = {
  completion: DetailedManagerSubmission | null;
  open: boolean;
  loading: boolean;
};

const dialogState = hookstate<DialogState>({
  completion: null,
  open: false,
  loading: false,
});

export const useSubmissionDialog = () => {
  const state = useHookstate(dialogState);

  const openDialog = async (completion: ManagerSubmission) => {
    resetDialog();

    const response = await getDetailedManagerSubmission(completion._id);
    if (!('result' in response)) {
      notification.error({ message: 'Error', description: response.message });
      return;
    }

    setTimeout(() => {
      state.completion.set(response.result);
      state.open.set(true);
    }, 0);
  };

  const closeDialog = () => {
    state.open.set(false);
  };

  const resetDialog = () => {
    state.completion.set(null);
  };

  return {
    state: state.get(),
    openDialog,
    closeDialog,
  };
};
