import { hookstate, useHookstate } from '@hookstate/core';
import { notification } from 'antd';
import { ConsumerCompletion } from '@/types';
import { get } from 'lodash';
import { getCompletionsById } from '@/services/consumer.service';

type Mode = 'single' | 'compare';

type DialogState = {
  completion: ConsumerCompletion | null;
  mode: Mode;
  duplications: ConsumerCompletion[];
  open: boolean;
  loading: boolean;
};

const dialogState = hookstate<DialogState>({
  completion: null,
  mode: 'single',
  duplications: [],
  open: false,
  loading: false,
});

export const useConsumerSubmissionDialog = () => {
  const state = useHookstate(dialogState);

  const openDialog = async ({ id }: ConsumerCompletion) => {
    resetDialog();

    let completion: ConsumerCompletion | null = null;
    try {
      const response = await getCompletionsById([id]);
      completion = response.completions?.[0];
    } catch (error) {}

    if (!completion) {
      notification.error({
        message: 'Error',
        description: 'Could not get completion data',
      });
      return;
    }

    setTimeout(() => {
      state.completion.set(completion);
      state.open.set(true);
    }, 0);
  };

  const setProcessing = () => {
    state.completion.merge({ processing: true });
  };

  const closeDialog = () => {
    state.open.set(false);
  };

  const resetDialog = () => {
    state.completion.set(null);
    state.mode.set('single');
    state.duplications.set([]);
  };

  const switchMode = (mode: Mode) => {
    state.mode.set(mode);

    if (mode === 'compare') {
      loadDuplications();
    }
  };

  const loadDuplications = async () => {
    state.loading.set(true);

    const completionsId = get(
      state.get().completion,
      'analysis.data.identifier.duplicationsId',
      [],
    );

    const { completions = [] } = await getCompletionsById(completionsId);

    state.duplications.set(completions);

    setTimeout(() => state.loading.set(false), 100);
  };

  return {
    state: state.get(),
    openDialog,
    closeDialog,
    setProcessing,
    switchMode,
  };
};
